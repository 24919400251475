import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { GlobalLoadingComponent } from '../../components/loading/global-loading/global-loading.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private overlayRef!: OverlayRef | null;

  constructor(private overlay: Overlay) {}

  public setLoading(loading: boolean) {
    //TODO? maybe use Andres approach with the loading counter?
    console.log('loading ', loading);
    if (loading) {
      if (!this.overlayRef) {
        this.overlayRef = this.overlay.create({
          positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
          hasBackdrop: true
        });
        this.overlayRef.attach(new ComponentPortal(GlobalLoadingComponent));
      }
    } else {
      this.overlayRef?.detach();
      this.overlayRef = null;
    }
    this.loading.next(loading);
  }

  public getLoading(): BehaviorSubject<boolean> {
    return this.loading;
  }
}
