import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {ApplicationDTO, ApplicationIds} from '../models/application';
import {environment} from "../../environments/local/environment";
import {IUser} from "../models/userinfo";
import {AppActionItem} from "../models/appmenu";
import {PlanningToolService} from "./planning-tool.service";
import {UtilService} from "./util.service";

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private _applicationsSubject = new BehaviorSubject<ApplicationDTO[]>([]);
  private sharedProfile = new BehaviorSubject<IUser | null>(null);
  private hasApplicationAccess = true;


  constructor(private http: HttpClient,
              private planningToolService: PlanningToolService,
              private utilService: UtilService) {
  }

  get applicationsSubject(): BehaviorSubject<ApplicationDTO[]> {
    return this._applicationsSubject;
  }


  public getOwnUser(): Observable<IUser> {
    return this.http.get<IUser>(environment.backend.endpoint.host +
      environment.users.endpoint.paths.prefix +
      environment.users.endpoint.paths.profile).pipe(
      tap(user => {
        if (user?.applications) {
          this._applicationsSubject.next(user.applications);
        } else {
          this._applicationsSubject.next([]);
        }
      })
    );
  }


  getSharedProfile(observableOnly: boolean = false) {
    if (this.sharedProfile.value === null && !observableOnly) {
      this.updateProfile();
    }
    return this.sharedProfile.asObservable();
  }

  resetSharedProfile(): void {
    this.sharedProfile.next(null);
  }

  updateProfile() {
    this.getOwnUser()
      .subscribe({
        next: user => {
          this.sharedProfile.next(user);
        },
        error: err => {
          this.sharedProfile.next(null);
          this.sharedProfile.error(err);
        }
      });
  }

  setAppActionItemClickAction(appMenuItem: AppActionItem) {
    switch (appMenuItem.config.appId) {
      case ApplicationIds.CUSTOMER_PORTAL:
        appMenuItem.clickHandler = () => {
          this.utilService.goToLink(environment.links.applications.hcp)
        }
        break;
      case ApplicationIds.HPLUS_SERVICE:
        appMenuItem.clickHandler = () => {
          this.utilService.goToLink(environment.links.applications.mh2);
        }
        break;
      case ApplicationIds.LEVEL_2:
        appMenuItem.clickHandler = () => {
          this.utilService.goToLink(environment.links.applications.l2)

        }
        break;
      case ApplicationIds.ELECTRICITY_CALCULATION_TOOL:
        appMenuItem.clickHandler = () => {
          this.planningToolService.openTool(ApplicationIds.ELECTRICITY_CALCULATION_TOOL);
        }
        break;
      case ApplicationIds.TENDER_TOOL:
        appMenuItem.clickHandler = () => {
          this.planningToolService.openTool(ApplicationIds.TENDER_TOOL);
        }
        break;
      case ApplicationIds.HPLUS_DONGLE:
        appMenuItem.clickHandler = () => {
          this.utilService.goToLink(environment.links.applications.dongle);
        }
        break;
      case ApplicationIds.REMOTE_ADMIN:
        appMenuItem.clickHandler = () => {
          this.utilService.goToLink(environment.links.applications.remote_admin);
        }
        break;
      case ApplicationIds.REMOTE_DESKTOP:
        appMenuItem.clickHandler = () => {
          this.utilService.goToLink(environment.links.applications.remote_desktop);
        }
        break;
      case ApplicationIds.OMS_TOOL:
        appMenuItem.clickHandler = () => {
          this.utilService.goToLink(environment.redirect.host);
        };
        break;
    }
  }

  setAppAccess(hasApplicationAccess: boolean) {
    this.hasApplicationAccess = hasApplicationAccess;
  }

  hasAppAccess() {
    return this.hasApplicationAccess;
  }
}
