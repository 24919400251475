import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/local/environment";
import {CompanyData} from "../models/company-data";

@Injectable({
    providedIn: 'root'
})
export class CompanyDataMgmtService {


    constructor(private http: HttpClient) {

    }

    public getCompanyData(): Observable<CompanyData> {
        return this.http.get<CompanyData>(
            environment.backend.endpoint.host +
            environment.domaindata.endpoint.paths.prefix +
            environment.domaindata.endpoint.paths.companydata
        );
    }
}
