import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ApplicationService} from "../services/application.service";

@Injectable({
  providedIn: 'root'
})
export class NoAppAccessGuard {

  constructor(private applicationService: ApplicationService, private router: Router) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.applicationService.hasAppAccess()) {
      return true;
    } else {
      this.router.navigate(['']);
    }
    return false;

  }

}
