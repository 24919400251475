import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../environments/local/environment";

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private sanitizer: DomSanitizer,
              private translateService: TranslateService,
              private router: Router) {
  }

  goToLink(link: string) {
    window.open(link, '_blank', 'noopener,noreferrer');
  }

  openAppUrlInNewTab(relativePath: string) {
    const link = environment.redirect.host + relativePath;
    this.goToLink(link)
  }
  isNS_ERROR(e: Error) {
    return e.name === 'NS_ERROR_FAILURE';
  }
}
